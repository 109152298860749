<template>
  <SubsidiaryMainStyled>
    <Panel v-if="subsidiary">
      <template v-slot:header>
        <router-link :to="{ name: 'organizations' }">
          {{ subsidiary.organization.name }}
        </router-link>
        <ChevronRightIcon />
        <span :class="isSubsidiaryAdmin ? 'subsidiary-name editable' : 'subsidiary-name value'" @click="activateEditSubsidiaryOverlay">
          <span>{{ subsidiary.name }}</span>
          <Edit2Icon v-if="isSubsidiaryAdmin" />
        </span>
      </template>
      <template v-slot:main>
        <div class="subsidiary-main">
          <Subpanel v-if="isSubsidiaryAdmin">
            <template v-slot:header>
              <div class="title">
                {{ $tc('setting', 2) }}
              </div>
            </template>
            <template v-slot:main>
              <div class="settings">
                <div class="cell option">
                  <OnOffToggle
                    class="on-off-toggle"
                    :value="subsidiary.nonAdminUsersCanSeeOtherUsers"
                    @toggle="toggleNonAdminUsersCanSeeOtherUsers(subsidiary)"
                  />
                  <span>{{ $t('nonAdminUsersCanSeeOtherUsers') }}</span>
                </div>
              </div>
            </template>
          </Subpanel>
          <Subpanel>
            <template v-slot:header>
              <div class="title">
                {{ $tc('user', 2) }}
              </div>
            </template>
            <template v-slot:main>
              <div class="members" v-if="subsidiaryMemberships.length > 0">
                <div class="cell username">
                  <strong>{{ $tc('username', 1) }}</strong>
                </div>
                <div class="cell email">
                  <strong>{{ $tc('email', 1) }}</strong>
                </div>
                <div class="cell role">
                  <strong>{{ $tc('role', 1) }}</strong>
                </div>
                <div class="cell spacer"></div>
                <template v-for="membership in subsidiaryMemberships">
                  <div class="cell username" :key="'username_' + membership.id">
                    {{ membership.user.username }}
                  </div>
                  <div class="cell email" :key="'email_' + membership.id">
                    <nobr>{{ membership.user.email }}</nobr>
                  </div>
                  <div class="cell role" :key="'role_' + membership.id">
                    <BasicSelect
                      v-if="isSubsidiaryAdmin"
                      :target="$tc('role', 1)"
                      :options="roles"
                      :selected="membership.selectedRole"
                      @change="setRole($event, membership)"
                    />
                    <template v-else>
                      {{ $tc(`membershipRoles.${membership.role.toLowerCase()}`) }}
                    </template>
                  </div>
                  <div class="cell spacer" :key="'spacer_' + membership.id">
                    <ButtonRound v-if="isSubsidiaryAdmin" :disabled="false" @click="confirmDeleteSubsidiaryMembership(membership)">
                      <XIcon />
                    </ButtonRound>
                    <BadgeStyled v-if="membership.userId === userId">
                      {{ $tc('itIsYou') }}
                    </BadgeStyled>
                  </div>
                </template>
              </div>
              <div class="no-members" v-else>
                <i18n path="messages.noDataWithType">
                  <template v-slot:type>
                    {{ $tc('member', 2) }}
                  </template>
                </i18n>
              </div>
            </template>
            <template v-slot:footer>
              <div class="add-member" v-if="isOrganizationAdmin">
                <Multiselect
                  class="multiselect"
                  track-by="id"
                  label="label"
                  :disabled="!canAddMember"
                  :placeholder="$t('actions.add')"
                  :selectLabel="$t('actions.pressEnterToSelect')"
                  :options="availableUsers"
                  :searchable="true"
                  :multiple="false"
                  :loading="$apollo.loading"
                  :internal-search="true"
                  :close-on-select="true"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="true"
                  @select="addMember"
                >
                  <template v-slot:noOptions>
                    {{
                      $t('messages.noAdditionalEntitiesAvailable', {
                        entity: $tc('user', 2),
                      })
                    }}
                  </template>
                </Multiselect>
              </div>
            </template>
          </Subpanel>
          <Subpanel>
            <template v-slot:header>
              <div class="title">
                {{ $tc('asset', 2) }}
              </div>
            </template>
            <template v-slot:main>
              <div class="assets" v-if="subsidiaryAssetsMapped.length > 0">
                <div class="cell name">
                  <strong>{{ $tc('name', 1) }}</strong>
                </div>
                <div class="cell site">
                  <strong>{{ $tc('site', 1) }}</strong>
                </div>
                <div class="cell spacer"></div>
                <template v-for="asset in subsidiaryAssetsMapped">
                  <div class="cell name" :key="'name_' + asset.id">
                    <nobr>{{ asset.name }}</nobr>
                  </div>
                  <div class="cell site" :key="'site_' + asset.id">
                    <nobr>{{ asset.siteName }}</nobr>
                  </div>
                  <div class="cell spacer" :key="'spacer_' + asset.id">
                    <ButtonRound v-if="isSubsidiaryAdmin" :disabled="false" @click="confirmDeleteAsset(asset)">
                      <XIcon />
                    </ButtonRound>
                  </div>
                </template>
              </div>
              <div class="no-assets" v-else>
                <i18n path="messages.noDataWithType">
                  <template v-slot:type>
                    {{ $tc('asset', 2) }}
                  </template>
                </i18n>
              </div>
            </template>
            <template v-slot:footer>
              <div class="add-asset" v-if="isOrganizationAdmin">
                <Multiselect
                  class="multiselect"
                  track-by="id"
                  label="label"
                  :disabled="!canAddAsset"
                  :placeholder="$t('actions.add')"
                  :selectLabel="$t('actions.pressEnterToSelect')"
                  :options="availableAssets"
                  :searchable="true"
                  :multiple="false"
                  :loading="$apollo.loading"
                  :internal-search="true"
                  :close-on-select="true"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="true"
                  @select="addAsset"
                >
                  <template v-slot:noOptions>
                    {{
                      $t('messages.noAdditionalEntitiesAvailable', {
                        entity: $tc('asset', 2),
                      })
                    }}
                  </template>
                </Multiselect>
              </div>
            </template>
          </Subpanel>
        </div>
      </template>
    </Panel>
  </SubsidiaryMainStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
// import { DateTime } from 'luxon'
import get from 'lodash/get'
import permissionsMixin from '@/mixins/permissions'
import Multiselect from 'vue-multiselect'
import { FlashMessages } from '@common/singletons'
import { Panel, Subpanel, BasicSelect, OnOffToggle } from '@common/components'

import SUBSIDIARY_QUERY from '#/graphql/organizations/subsidiary/show.gql'
import CREATE_SUBSIDIARY_MEMBERSHIP_MUTATION from '#/graphql/organizations/subsidiary/membership/create.gql'
import DELETE_SUBSIDIARY_MEMBERSHIP_MUTATION from '#/graphql/organizations/subsidiary/membership/delete.gql'
import UPDATE_SUBSIDIARY_MEMBERSHIP_MUTATION from '#/graphql/organizations/subsidiary/membership/update.gql'
import ADD_ASSET_TO_SUBSIDIARY_MUTATION from '#/graphql/organizations/subsidiary/asset/add.gql'
import DELETE_ASSET_FROM_SUBSIDIARY_MUTATION from '#/graphql/organizations/subsidiary/asset/delete.gql'
import TOGGLE_SUBSIDIARY_USER_VISIBILITY_MUTATION from '#/graphql/organizations/subsidiary/toggleSubsidiaryUserVisibility.gql'
import AVAILABLE_ROLES_QUERY from '#/graphql/misc/availableRoles.gql'

import {
  // PlusIcon,
  // CheckIcon,
  Edit2Icon,
  XIcon,
  ChevronRightIcon,
} from 'vue-feather-icons'

import {
  // ButtonSolid,
  ButtonRound as ButtonBase,
} from '@styles/buttons'

import {
  getUserIdFromToken,
  // delay,
} from '@common/utils'

const SubsidiaryMainStyled = styled('div')`
  margin: 1rem;
  > section > header {
    font-size: 1.3rem;
  }

  .subsidiary-name.editable {
    display: flex;
    align-items: center;

    cursor: pointer;

    & > svg {
        margin-left: 0.5rem;
    }

    &:hover {
      text-decoration: underline;

      & > svg {
        color: ${p => p.theme.colors.primaryLighter};
      }
    }
  }

  .subsidiary-name.value {
    pointer-events: none;
  }

  .subsidiary-main {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: .5rem;
    > section {
      grid-template-rows: min-content auto min-content;
    }
    > section > header {
      border-top: 1px solid ${p => p.theme.colors.panelHeaderBorderBottom};
      border-bottom: 1px solid ${p => p.theme.colors.panelHeaderBorderBottom};
    }
    > section:first-child > header {
      border-top: none;
    }
  }
  > section {
    margin-bottom: 3rem;
  }
  > section > main {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
  }
  .title {
    font-size: 1.1rem;
  }
  .settings, .members, .assets {
    display: grid;
    grid-template-columns: repeat(3, minmax(4rem, min-content)) auto;
    grid-auto-rows: minmax(2rem, 4rem);
    grid-gap: 1rem;
    > .cell {
      display: flex;
      align-items: center;
      word-break;
    }
    .actions {
      display: flex;
    }
  }
  .settings {
    .option {
      grid-column: span 4;
      padding: 1rem;
      .on-off-toggle {
        margin-right: .5rem;
      }
    }
  }
  .assets {
    grid-template-columns: repeat(2, minmax(4rem, min-content)) auto;
  }
  .no-members, .no-assets {
    display: flex;
    grid-column: span 4;
    padding: 1rem;
  }
  .add-member, .add-asset {
    display: flex;
    padding: 1rem 0;
    .multiselect{
      max-width: 600px;
    }
  }
`
const ButtonRound = styled(ButtonBase)`
  .feather {
    width: 12px;
    height: 12px;
  }
  border-radius: 0;
  margin-left: 0.5rem;
  background: ${p => p.theme.colors.primary};
  &:hover {
    background: ${p => p.theme.colors.primaryActive};
  }
`

// const ButtonConfirm = styled(ButtonSolid)`
//   margin: 0;
//   border-radius: 0;
// `

const BadgeStyled = styled('span')`
  font-size: 0.7rem;
  border-radius: 3px;
  padding: 0.25rem;
  margin-left: 1rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.green};
`

export default {
  mixins: [permissionsMixin],
  components: {
    SubsidiaryMainStyled,
    Panel,
    BasicSelect,
    Subpanel,
    BadgeStyled,
    ChevronRightIcon,
    Multiselect,
    // ButtonSolid,
    // ButtonConfirm,
    ButtonRound,
    // PlusIcon,
    // CheckIcon,
    // Edit2Icon,
    XIcon,
    OnOffToggle,
    Edit2Icon,
  },
  data() {
    return {
      subsidiary: null,
      isAddingMember: false,
      isAddingAsset: false,
      userId: getUserIdFromToken(this.$keycloak.token),
      availableRoles: [],
    }
  },
  computed: {
    canAddMember() {
      return !this.isAddingMember && this.isOrganizationAdmin
    },
    canAddAsset() {
      return !this.isAddingAsset && this.isOrganizationAdmin
    },
    subsidiaryId() {
      return get(this.subsidiary, 'id')
    },
    isOrganizationAdmin() {
      const userId = getUserIdFromToken(this.$keycloak.token)
      return !!this.organizationMemberships.find(membership => membership.userId === userId && membership.role === 'ADMIN')
    },
    isSubsidiaryAdmin() {
      if (this.isOrganizationAdmin) {
        return true
      }
      const userId = getUserIdFromToken(this.$keycloak.token)
      return !!this.subsidiaryMemberships.find(membership => membership.userId === userId && membership.role === 'ADMIN')
    },
    subsidiaryAssetsMapped() {
      return this.subsidiaryAssets.map(asset => ({
        ...asset,
        siteName: get(asset, 'site.name', '-'),
        label: asset.name,
      }))
    },
    subsidiaryAssets() {
      return get(this.subsidiary, 'assets', [])
    },
    organizationAssets() {
      return get(this.subsidiary, 'organization.assets', [])
    },
    organizationMemberships() {
      return get(this.subsidiary, 'organization.memberships', [])
    },
    availableUsers() {
      const alreadyPresent = this.subsidiaryMemberships.map(membership => membership.user)
      return this.organizationMemberships
        .map(membership => membership.user)
        .filter(user => !alreadyPresent.find(f => user.id === f.id))
        .map(user => ({
          ...user,
          label: `${user.username} - ${user.email}`,
        }))
    },
    availableAssets() {
      const alreadyPresent = this.subsidiaryAssets
      return this.organizationAssets
        .filter(asset => !alreadyPresent.find(f => asset.id === f.id))
        .map(asset => ({
          ...asset,
          label: asset.site ? `${asset.name} - ${asset.site.name}` : `${asset.name}`,
        }))
    },
    // membershipsMapped(){
    //   return this.memberships.map(membership => ({
    //     ...membership,
    //     label: get(this.options, 'mode', 'unknown') === 'organization'
    //       ? get(membership, 'organization.name', 'invalid organization')
    //       : get(membership, 'user.username', 'invalid user'),
    //     selectedRole: this.roles.find(f => f.id === membership.role),
    //   }))
    // },
    subsidiaryMemberships() {
      return get(this.subsidiary, 'memberships', []).map(membership => ({
        ...membership,
        selectedRole: this.roles.find(f => f.id === membership.role),
      }))
    },
    roles() {
      return get(this.availableRoles, 'enumValues', []).map(roleEnum => ({
        id: roleEnum.name,
        label: this.$tc(`membershipRoles.${roleEnum.name.toLowerCase()}`, 1),
      }))
    },
  },
  methods: {
    activateEditSubsidiaryOverlay() {
      this.$root.$emit('activateOverlay', 'EditSubsidiaryNameOverlay', {
        organizationID: this.subsidiary.organization.id,
        subsidiary: this.subsidiary,
        queryToUpdate: 'SUBSIDIARY',
      })
    },
    async toggleNonAdminUsersCanSeeOtherUsers(subsidiary) {
      try {
        await this.$apollo.mutate({
          mutation: TOGGLE_SUBSIDIARY_USER_VISIBILITY_MUTATION,
          variables: {
            where: {
              id: subsidiary.id,
            },
          },
        })
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async addAsset(asset) {
      this.isAddingAsset = true
      await this.$apollo.mutate({
        mutation: ADD_ASSET_TO_SUBSIDIARY_MUTATION,
        variables: {
          subsidiaryId: this.subsidiaryId,
          assetId: asset.id,
        },
        update: store => {
          const { subsidiary } = store.readQuery({
            query: SUBSIDIARY_QUERY,
            variables: {
              where: {
                id: this.$route.params.id,
              },
            },
          })
          subsidiary.assets.push(asset)
          store.writeQuery({
            query: SUBSIDIARY_QUERY,
            variables: {
              where: {
                id: this.$route.params.id,
              },
            },
            data: {
              subsidiary,
            },
          })
          FlashMessages.$emit('success', this.$t('messages.entityAddedSuccessfully', { entity: this.$tc('asset', 1) }), {
            timeout: 3000,
          })
          this.isAddingAsset = false
        },
      })
    },
    confirmDeleteAsset(asset) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'Asset',
        instance: asset,
        labelKey: 'name',
        onConfirm: this.deleteAsset,
        onConfirmArgs: asset,
      })
    },
    async deleteAsset(asset) {
      try {
        this.isAddingAsset = true
        await this.$apollo.mutate({
          mutation: DELETE_ASSET_FROM_SUBSIDIARY_MUTATION,
          variables: {
            subsidiaryId: this.subsidiaryId,
            assetId: asset.id,
          },
          update: store => {
            const { subsidiary } = store.readQuery({
              query: SUBSIDIARY_QUERY,
              variables: {
                where: {
                  id: this.$route.params.id,
                },
              },
            })
            subsidiary.assets = subsidiary.assets.filter(f => f.id !== asset.id)
            store.writeQuery({
              query: SUBSIDIARY_QUERY,
              variables: {
                where: {
                  id: this.$route.params.id,
                },
              },
              data: {
                subsidiary,
              },
            })
            FlashMessages.$emit('success', this.$t('messages.entityDeletedSuccessfully', { entity: this.$tc('asset', 1) }), {
              timeout: 3000,
            })
          },
        })
        return true
      } catch (err) {
        FlashMessages.$emit('error', err)
      } finally {
        this.isAddingAsset = false
      }
    },
    async deleteMember() {
      // TODO: how can this work?
      await this.$apollo.mutate({
        mutation: DELETE_SUBSIDIARY_MEMBERSHIP_MUTATION,
      })
    },
    async addMember(user) {
      this.isAddingMember = true
      await this.$apollo.mutate({
        mutation: CREATE_SUBSIDIARY_MEMBERSHIP_MUTATION,
        variables: {
          data: {
            subsidiary: {
              connect: {
                id: this.subsidiaryId,
              },
            },
            role: 'USER',
            userId: user.id,
          },
        },
        update: (store, { data }) => {
          const { subsidiary } = store.readQuery({
            query: SUBSIDIARY_QUERY,
            variables: {
              where: {
                id: this.$route.params.id,
              },
            },
          })
          subsidiary.memberships.push(data.createSubsidiaryMembership)
          store.writeQuery({
            query: SUBSIDIARY_QUERY,
            variables: {
              where: {
                id: this.$route.params.id,
              },
            },
            data: {
              subsidiary,
            },
          })
          FlashMessages.$emit('success', this.$t('messages.entityAddedSuccessfully', { entity: this.$tc('member', 1) }), {
            timeout: 3000,
          })
        },
      })
      this.isAddingMember = false
    },
    async setRole(role, membership) {
      if (get(membership, '__typename', 'invalid') === 'SubsidiaryMembership') {
        const res = await this.$apollo.mutate({
          mutation: UPDATE_SUBSIDIARY_MEMBERSHIP_MUTATION,
          variables: {
            data: {
              role: role.id,
            },
            where: {
              id: membership.id,
            },
          },
        })
        if (res) {
          FlashMessages.$emit('success', this.$t('messages.entityUpdatedSuccessfully', { entity: this.$tc('member', 1) }), {
            timeout: 3000,
          })
        }
      }
    },
    confirmDeleteSubsidiaryMembership(membership) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'Membership',
        onConfirm: this.deleteSubsidiaryMembership,
        onConfirmArgs: membership,
      })
    },
    async deleteSubsidiaryMembership(membership) {
      try {
        this.isAddingMember = true
        await this.$apollo.mutate({
          mutation: DELETE_SUBSIDIARY_MEMBERSHIP_MUTATION,
          variables: {
            where: {
              id: membership.id,
            },
          },
          update: store => {
            const { subsidiary } = store.readQuery({
              query: SUBSIDIARY_QUERY,
              variables: {
                where: {
                  id: this.$route.params.id,
                },
              },
            })
            subsidiary.memberships = subsidiary.memberships.filter(f => f.id !== membership.id)
            store.writeQuery({
              query: SUBSIDIARY_QUERY,
              variables: {
                where: {
                  id: this.$route.params.id,
                },
              },
              data: {
                subsidiary,
              },
            })
            FlashMessages.$emit('success', this.$t('messages.entityDeletedSuccessfully', { entity: this.$tc('member', 1) }), {
              timeout: 3000,
            })
          },
        })
        return true
      } catch (err) {
        FlashMessages.$emit('error', err)
      } finally {
        this.isAddingMember = false
      }
    },
  },
  apollo: {
    subsidiary: {
      query: SUBSIDIARY_QUERY,
      variables() {
        return {
          where: {
            id: this.$route.params.id,
          },
        }
      },
      skip() {
        return !get(this.$route, 'params.id', false)
      },
    },
    availableRoles: {
      query: AVAILABLE_ROLES_QUERY,
      variables() {
        return {
          enumName: 'SubsidiaryMembershipRoleEnum',
        }
      },
    },
  },
}
</script>
